<template>
  <Navigation :index="'storagesManage'" ref="navigation">
    <div div class="topnavigation" style="gap: 10px">
      <el-input
        style="width: 190px"
        v-model="StoragePadding.Search" @keydown.enter="GetStorageList"
        :placeholder="tools.GetLanguageValue('web.请输入名称/Code')"
      ></el-input>


      <el-checkbox-group v-model="SearchProductTypes">
            <el-button v-for="(attr, index) in ProductTypes" :key="index">
              <el-checkbox :label="attr.Key"> {{ attr.Value }}</el-checkbox>
            </el-button>
          </el-checkbox-group>



      <el-button type="primary" @click="GetStorageList">
        {{ tools.GetLanguageValue("web.查询") }}</el-button
      >
    </div>
    <div :style="{ height: ContentHeight + 'px' }">
      <el-table border :data="StoragesDatas" style="width: 100%; height: 100%">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column
          :label="tools.GetLanguageValue('web.商品名称')"
          width="320"
          prop="ProductName"
        >
          <template #default="scope">
            <p
              v-if="scope.row.ShowAllName"
              @click="scope.row.ShowAllName = false"
            >
              {{ scope.row.ProductName }}
            </p>
            <p v-else class="overflow" @click="scope.row.ShowAllName = true">
              {{ scope.row.ProductName }}
            </p>
          </template>
        </el-table-column>
        <!-- <el-table-column
          :label="tools.GetLanguageValue('web.图片')"
          width="100"
        >
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-image
                width="60"
                height="60"
                :src="scope.row.ProductImage"
                class="avatar"
                @click="
                  () => {
                    this.ImageListDetailWindow = true;
                    this.ImageListDetailUrl = scope.row.ProductImage;
                  }
                "
              />
            </div>
          </template>
        </el-table-column> -->
        <el-table-column
          :label="tools.GetLanguageValue('web.属性')"
          width="180"
        >
          <template #default="scope">
            <el-tag
              v-for="(item, index) in scope.row.SkuAttribute"
              :key="index"
              style="margin: 3px"
            >
              {{ item.AttributeName }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          :label="tools.GetLanguageValue('web.库存')"
          width="80"
          prop="Quantity"
        />
        <el-table-column
          :label="tools.GetLanguageValue('web.商品类型')"
          width="180"
          prop="ProductTypeFormat"
        />

        <el-table-column
          :label="tools.GetLanguageValue('web.操作')"
          width="320"
        >
          <template #default="scope">
            <el-button
              type="success"
              size="small"
              @click="OpenStorageWindow(scope.row)"
            >
              {{ tools.GetLanguageValue("web.入库") }}</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="OpenStorageRemovalWindow(scope.row)"
            >
              {{ tools.GetLanguageValue("web.出库") }}</el-button
            >
            <el-button size="small" @click="CalculateStock(scope.row)">
              {{ tools.GetLanguageValue("web.计算库存") }}</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="OpenDetailStorageWindow(scope.row)"
            >
              {{ tools.GetLanguageValue("web.库存详细") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div style="padding: 5px 0px">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="StoragePadding.Size"
        :total="StoragePadding.Count"
        @current-change="
          (s) => {
            StoragePadding.Page = s;
            GetStorageList();
          }
        "
      >
      </el-pagination>
    </div>
  </Navigation>

  <!-- 入库 -->
  <el-dialog
    v-model="StorageWindow"
    :title="tools.GetLanguageValue('web.入库')"
    width="700px"
    :show-close="false"
  >
    <el-form ref="form" :model="StorageOpt" label-width="80px">
      <el-form-item :label="tools.GetLanguageValue('web.商品名称')">
        <span style="font-size: 16pt; color: red">
          {{ StorageOpt.ProductName + "-" + StorageOpt.AttributeFormat + "" }}
        </span>
      </el-form-item>
      <el-form-item :label="tools.GetLanguageValue('web.货架')">
        <el-select
          v-model="StorageOpt.WarehouseRackuId"
          :placeholder="tools.GetLanguageValue('web.请选择货架')"
        >

        <el-option
                :label="tools.GetLanguageValue('web.请选择货架')"
                value="00000000-0000-0000-0000-000000000000"
              >
                {{tools.GetLanguageValue('web.请选择货架') }}
              </el-option> 
          <el-option
            v-for="item in WarehouseRackuDatas"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          >
            {{ item.Name }}
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="tools.GetLanguageValue('web.数量')"
        v-if="StorageOpt.ProductType != 100"
      >
        <el-input-number v-model="StorageOpt.Quantity"></el-input-number>
      </el-form-item>

      <el-form-item label="SN" v-if="StorageOpt.ProductType == 100">
        <el-input v-model="StorageOpt.SN"></el-input>
      </el-form-item>
      <el-form-item label="IMEI" v-if="StorageOpt.ProductType == 100">
        <el-input v-model="StorageOpt.IMEI"></el-input>
      </el-form-item>

      <el-form-item :label="tools.GetLanguageValue('web.备注')">
        <el-input v-model="StorageOpt.Remarks"></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="StorageWindow = false">
          {{ tools.GetLanguageValue("web.关闭") }}</el-button
        >
        <el-button @click="AutoInsertStock()">
          {{ tools.GetLanguageValue("web.保存") }}</el-button
        >
      </span>
    </template>
  </el-dialog>

  <!-- 出库 -->
  <el-dialog
    v-model="StorageRemovalWindow"
    :title="tools.GetLanguageValue('web.出库')"
    width="1300px"
    :show-close="false"
  >
    <el-form ref="form" :model="StorageRemovalOpt" label-width="80px">
      <el-form-item :label="tools.GetLanguageValue('web.商品名称')">
        <span style="font-size: 16pt; color: red">
          {{ StorageOpt.ProductName }}
        </span>
        <span
          style="font-size: 16pt; color: red"
          v-if="StorageOpt.ProductType == 100"
        >
          {{ StorageOpt.AttributeFormat + "" }}
        </span>
      </el-form-item>
      <el-form-item :label="tools.GetLanguageValue('web.现有库存')">
        <span style="font-size: 16pt; color: red">
          {{ StorageRemovalOpt.Quantity }}
        </span>
      </el-form-item>

      <el-form-item
        :label="tools.GetLanguageValue('web.出库数量')"
        v-if="StorageOpt.ProductType != 100"
      >
        <el-input-number
          v-model="StorageRemovalParams.StockRemovalQuantity"
          :max="StorageOpt.Quantity"
        ></el-input-number>
      </el-form-item>
      <el-form-item
        :label="tools.GetLanguageValue('web.出库备注')"
        v-if="StorageOpt.ProductType != 100"
      >
        <el-input v-model="StorageOpt.Remarks"></el-input>
      </el-form-item>
      <el-button v-if="StorageOpt.ProductType != 100" @click="StockRemoval()">
        {{ tools.GetLanguageValue("web.出库") }}</el-button
      >
    </el-form>

    <el-table
      border
      :data="StorageRemovalOpt.StorageTakeNotes"
      style="width: 100%"
      height="600px"
      v-if="StorageOpt.ProductType == 100"
    >
      <el-table-column type="index" width="50"> </el-table-column>

      <el-table-column
        :label="tools.GetLanguageValue('web.数量')"
        width="80"
        prop="Quantity"
      />

      <el-table-column label="SN" width="180" prop="SN" />
      <el-table-column label="IMEI" width="180" prop="IMEI" />
      <el-table-column label="锁定库存" width="100" prop="LockinStock">
        <template #default="scope">
          <el-tag v-if="scope.row.LockinStock">
            {{ tools.GetLanguageValue("web.锁定") }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
        :label="tools.GetLanguageValue('web.货架')"
        width="180"
        prop="WarehouseRackusName"
      />
      <el-table-column
        :label="tools.GetLanguageValue('web.备注')"
        width="180"
        prop="Remarks"
      />
      <el-table-column
        :label="tools.GetLanguageValue('web.入库时间')"
        width="180"
        prop="AccomplishDateTimeFormat"
      />
      <el-table-column :label="tools.GetLanguageValue('web.操作')" width="280">
        <template #default="scope">
          <el-button
            type="danger"
            size="small"
            @click="StockRemovalOnly(scope.row)"
          >
            {{ tools.GetLanguageValue("web.出库") }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="StorageRemovalWindow = false">
          {{ tools.GetLanguageValue("web.关闭") }}</el-button
        >
      </span>
    </template>
  </el-dialog>

  <!-- 库存详细 -->
  <el-dialog
    v-model="DetailStorageWindow"
    :title="tools.GetLanguageValue('web.出库')"
    width="1300px"
    :show-close="false"
  >
    <el-form ref="form" :model="StorageRemovalOpt" label-width="80px">
      <el-form-item :label="tools.GetLanguageValue('web.商品名称')">
        <span style="font-size: 16pt; color: red">
          {{ StorageOpt.ProductName }}
        </span>
        <span
          style="font-size: 16pt; color: red"
          v-if="StorageOpt.ProductType == 100"
        >
          {{ StorageOpt.AttributeFormat + "" }}
        </span>
      </el-form-item>
      <el-table
        border
        :data="StorageTakeNotes"
        style="width: 100%"
        height="600px"
        v-if="StorageOpt.ProductType == 100"
      >
        <el-table-column type="index" width="50"> </el-table-column>

        <el-table-column
          :label="tools.GetLanguageValue('web.数量')"
          width="80"
          prop="Quantity"
        />
        <!-- <el-table-column label="操作类型" width="100" prop="AccessFormat">
          <template #default="scope">
            <el-button v-if="scope.row.Access == 1" type="danger" size="small">
              {{ scope.row.AccessFormat }}
            </el-button>
            <el-button v-if="scope.row.Access == 0" type="success" size="small">
              {{ scope.row.AccessFormat }}
            </el-button>
          </template>
        </el-table-column> -->
        <el-table-column label="SN" width="180" prop="SN" />
        <el-table-column
          :label="tools.GetLanguageValue('web.是否出库')"
          width="180"
          prop="StockRemoval"
        >
          <template #default="scope">
            <el-button v-if="scope.row.StockRemoval" type="danger" size="small">
              {{ tools.GetLanguageValue("web.已出库") }}
            </el-button>
            <el-button v-else type="success" size="small">
              {{ tools.GetLanguageValue("web.未出库") }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
          :label="tools.GetLanguageValue('web.是否锁定')"
          width="180"
          prop="LockinStock"
        >
          <template #default="scope">
            <div v-if="scope.row.StockRemoval">--</div>
            <div v-else>
              <el-button
                v-if="scope.row.LockinStock"
                type="danger"
                size="small"
              >
                {{ tools.GetLanguageValue("web.已锁定") }}
              </el-button>
              <span v-else> -- </span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="IMEI" width="180" prop="IMEI" />
        <el-table-column
          :label="tools.GetLanguageValue('web.货架')"
          width="180"
          prop="WarehouseRackusName"
        />
        <el-table-column
          :label="tools.GetLanguageValue('web.备注')"
          width="180"
          prop="Remarks"
        />
        <el-table-column
          :label="tools.GetLanguageValue('web.入库时间')"
          width="180"
          prop="AccomplishDateTimeFormat"
        />
        <el-table-column
          :label="tools.GetLanguageValue('web.操作人')"
          width="180"
          prop="optUser"
        />
        <el-table-column
          :label="tools.GetLanguageValue('web.操作')"
          width="180"
          fixed="right"
        >
          <template #default="scope">
            <el-button
              type="danger"
              size="small"
              v-if="!scope.row.StockRemoval && !scope.row.LockinStock"
              @click="StockRemovalOnly(scope.row)"
            >
              {{ tools.GetLanguageValue("web.出库") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <div style="padding: 5px 0px">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="CurrentPage"
          :page-size="StoragRecordPadding.Size"
          :current-page="StoragRecordPadding.Page"
          :total="StoragRecordPadding.Count"
        >
        </el-pagination>
      </div>
    </el-form>
  </el-dialog>

  <!-- 列表的大图 -->
  <el-dialog
    :title="tools.GetLanguageValue('web.大图')"
    v-model="ImageListDetailWindow"
    style="width: 1200px; text-align: center"
  >
    <div style="">
      <img :src="ImageListDetailUrl" style="width: 1000px" />
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="ImageListDetailWindow = false">
          {{ tools.GetLanguageValue("web.关闭") }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script >
import axios from "../../../../commons/AxiosMethod.js";
import tools from "../../../../commons/tools.js";

import { ElMessageBox, ElMessage } from "element-plus";
import Navigation from "../../../../components/Modules/Navigation.vue";
export default {
  // eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types aboutus
  data() {
    return {
      tools: tools,
      clientWidth: document.body.clientWidth,
      clientHeight: document.documentElement.clientHeight,
      ContentHeight: 900,
      StorageWindow: false,
      ActiveItem: "goodsInfo",
      StoragesDatas: [], //库存管理数据
      StoragePadding: {
        Page: 1,
        Size: 50,
        Count: 0,
        Where:{}
      },
      ImageListDetailWindow: false,
      WarehouseRackusWhere: {},
      WarehouseRackuDatas: [],
      StorageOpt: {},

      StorageRemovalWindow: false, //出库
      StorageRemovalOpt: {
        StorageTakeNotes: [],
      },

      StorageRemovalParams: {}, //出库参数
      DetailStorageWindow: false,
      StorageTakeNotes: [], //出入库记录
      StoragRecordPadding: {
        Page: 1,
        Size: 10,
        Count: 0,
      },
      
      SearchProductTypes: [],
      ProductTypes: [],
    };
  },
  name: "App",
  mounted() {
    this.GetStorageList();
    this.GetWarehouseRackus();
    this.GetProductType();
    
    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.clientHeight = document.documentElement.clientHeight;
        this.ContentHeight = document.documentElement.clientHeight - 120;
        this.$refs.navigation.onresize();
      })();
    };
    this.ContentHeight = this.clientHeight - 120;
  },

  components: {
    Navigation,
  },
  methods: {
    
    //获取商品类型
    GetProductType() {
      const param = {};
      axios.apiMethod("/sys/SystemData/GetProductType", "get", {}, (result) => {
        if (result.Code == 200) {
          this.ProductTypes = result.Data;
        } else {
          ElMessageBox({
            message: result.FullMsg,
            type: "error",
          });
        }
      });
    },

    CurrentPage(value) {
      this.StoragRecordPadding.Page = value;
      this.GetStoragRecord();
    },
    GetStoragRecord() {
      this.StoragRecordPadding.Where = {
        Id: this.StorageOpt.Id,
      };

      axios.apiMethod(
        "/stores/Storage/GetStoragRecord",
        "post",
        this.StoragRecordPadding,
        (result) => {
          this.StorageTakeNotes = result.Data.Datas;
          this.StoragRecordPadding.Page = result.Data.Page;
          this.StoragRecordPadding.Size = result.Data.Size;
          this.StoragRecordPadding.Count = result.Data.Count;
        }
      );
    },

    OpenDetailStorageWindow(item) {
      this.StorageOpt = item;
      this.DetailStorageWindow = true;
      this.GetStoragRecord();
    },
    //计算库存
    CalculateStock(row) {
      ElMessageBox.confirm(
        "<span style='color:red'>您确定要重新计算库存吗</span>",
        "提示",
        {
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          axios.apiMethod(
            "/stores/Storage/CalculateStock",
            "get",
            { id: row.Id },
            (result) => {
              if (result.Data.IsSuccess) {
                this.GetStorageList();
              } else {
                ElMessageBox({
                  title: "提示",
                  message: result.Data.Msg,
                  type: "error",
                });
              }
            }
          );
        })
        .catch(() => {
          // catch error
        });
    },

    //数量出库
    StockRemoval() {
      axios.apiMethod(
        "/stores/Storage/StockRemoval",
        "post",
        this.StorageRemovalParams,
        (result) => {
          if (result.Data.IsSuccess) {
          } else {
            ElMessageBox({
              title: "提示",
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    },

    //单一出库
    StockRemovalOnly(item) {
      axios.apiMethod(
        "/stores/Storage/StockRemovalOnly",
        "post",
        item,
        (result) => {
          if (result.Data.IsSuccess) {
            this.StorageRemovalOpt = {
              StorageTakeNotes: [],
            };
            axios.apiMethod(
              "/stores/Storage/GetStoragProductSKU",
              "post",
              this.StorageOpt,
              (result) => {
                this.StorageRemovalOpt = result.Data;
                this.StorageRemovalOpt.Quantity = this.StorageOpt.Quantity;
                this.StorageRemovalOpt.ProductName =
                  this.StorageOpt.ProductName;
              }
            );
          } else {
            ElMessageBox({
              title: "提示",
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    },

    //打开出库页面
    OpenStorageRemovalWindow(item) {
      this.StorageOpt = item;

      this.StorageRemovalOpt = {
        StorageTakeNotes: [],
      };
      axios.apiMethod(
        "/stores/Storage/GetStoragProductSKU",
        "post",
        this.StorageOpt,
        (result) => {
          this.StorageRemovalWindow = true;

          this.StorageRemovalOpt = result.Data;
          this.StorageRemovalOpt.Quantity = this.StorageOpt.Quantity;
          this.StorageRemovalOpt.ProductName = this.StorageOpt.ProductName;

          this.StorageRemovalParams.StockRemovalQuantity = 0;
          this.StorageRemovalParams.ProductName = this.StorageOpt.ProductName;
          this.StorageRemovalParams.Id = this.StorageOpt.Id;
        }
      );
    },

    //自动增加库存
    AutoInsertStock() {
      axios.apiMethod(
        "/stores/Storage/AutoInsertStock",
        "post",
        this.StorageOpt,
        (result) => {
          if (result.Data.IsSuccess) {
            this.StorageWindow = false;
            this.GetStorageList();
            this.StorageOpt = {};
          } else {
            ElMessageBox({
              title: "提示",
              message: result.Data.Msg,
              type: "error",
            });
          }
        }
      );
    },
    //查询货架
    GetWarehouseRackus() {
      const param = {};
      axios.apiMethod(
        "/stores/Data/GetWarehouseRackus",
        "post",
        this.WarehouseRackusWhere,
        (result) => {
          this.WarehouseRackuDatas = result.Data;
        }
      );
    },

    //打开入库页面
    OpenStorageWindow(item) {
      this.StorageOpt = {
        ProductName: item.ProductName,
        AttributeFormat: item.AttributeFormat,
        Quantity: 0,
        WarehouseRackuId: "00000000-0000-0000-0000-000000000000",
        Remarks: "",
        Id: item.Id,
        ProductId: item.ProductId,
        ProductType: item.ProductType,
        IMEI: "",
        SN: "",
      };
      this.StorageWindow = true;
    },
    ///获取库存列表
    GetStorageList() {


      this.StoragePadding.Where.ProductTypes = this.SearchProductTypes;
      const param = {};
      axios.apiMethod(
        "/stores/Storage/GetStorageList",
        "post",
        this.StoragePadding,
        (result) => {
          if (result.Code == 200) {
            this.StoragesDatas = result.Data.Datas;
            this.StoragePadding.Search = "";

            this.StoragePadding.Page = result.Data.Page;
            this.StoragePadding.Size = result.Data.Size;
            this.StoragePadding.Count = result.Data.Count;
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error",
            });
          }
        }
      );
    },
  },
};
</script>
  
<style scoped>
.main-wrapper {
  padding: 5px;
}
.overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
  